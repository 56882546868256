<template>
  <v-app class="pt-15">

    <customize-header homeRouter="/shortMessage/Home"
      homeTitle="短信服务"
      :links="links"></customize-header>
    <router-view></router-view>
    <customize-suspendBtn></customize-suspendBtn>
    <customize-footer></customize-footer>
    
  </v-app>
</template>

<script>
export default {
  name: 'shortMessageIndex',
  data: () => ({
    links: [
      {
        title: "首页",
        router: "/shortMessage/Home",
        children: []
      },
      {
        title: "解决方案",
        router: "/shortMessage/solution",
        children: []
      },
    ],
    
  }),
  
};
</script>
<style lang="scss" scoped>

</style>